
<mat-dialog-content>
  <p class="content">
    {{ "COMMON.change" | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="save" (click)="save(true)">
    {{ "COMMON.SAVE" | translate }}
  </button>
  <button mat-button mat-dialog-close>
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>